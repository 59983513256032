import Link from 'next/link';
import * as React from 'react';

export const Footer: React.FunctionComponent = () => (
  <footer className="  text-md bg-black  relative overflow-hidden pb2">
    {/* <div className=" py-2 md:w-50-ns w-full w-100 flex md:gap-4 gap-3 justify-center">
      <Link href="/about" legacyBehavior>
        <span className="white no-underline fw6  b db pv1 mv1 ">About</span>
      </Link>
      <div className="h-[1px] bg-[#d44727] md:w-[30px] w-[10px] my-auto"></div>
      <Link href="/events" legacyBehavior>
        <span className="white no-underline fw6  b db pv1 mv1">Events</span>
      </Link>
      <div className="h-[1px] bg-[#f1b8a7] md:w-[30px] w-[10px] my-auto"></div>
      <Link href="/terms" legacyBehavior>
        <span className="white no-underline fw6  b db pv1 mv1">Terms</span>
      </Link>
      <div className="h-[1px] bg-[#4d748b] md:w-[30px] w-[10px] my-auto"></div>
      <Link href="/privacy" legacyBehavior>
        <span className="white no-underline fw6  b db pv1 mv1">Privacy Policy</span>
      </Link>
    </div>
    <div className=" border-t-2 text-xs text- font-semibold text-white p-2 h-fit flex justify-between">
      <div className="pt-2">© 2023 TBA. All Rights Reserved.</div>
      <div className="flex mx-2">
  
        <div className="flex gap-2">
          <a href="https://www.instagram.com/whatstba/" target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://twitter.com/WhatsTBA" target="_blank" rel="noreferrer">
            <TwitterIcon />
          </a>
        </div>
      </div>
    </div> */}
  </footer>
);
