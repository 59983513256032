import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';

const CaboNav = ({ visible, setVisible }) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const handleMouseDown = (e) => {
    toggleMenu();
    e.stopPropagation();
  };

  const toggleMenu = () => {
    setVisible(!visible);
  };
  var visibility = 'hide';

  if (visible) {
    visibility = 'show';
  }

  return (
    // Desktop Menu
    <nav className="absolute top-0 w-full bg-transparent z-50 font-solid-grooves text-2xl">
      <div className="hidden md:flex gap-12 p-5 w-full justify-center text-white border-y border-[#FFFF4E] ">
        {router.pathname !== '/' && (
          <Image alt="" width={200} height={40} src="/static/images/cabo/beyond-borders-logo-cabo.png" />
        )}

        <div>
          <ul className={`flex gap-6`}>
            <li className=" hover:text-gray-200">
              <Link href="/">Home</Link>
            </li>
            <li className=" hover:text-gray-200">
              <Link href="/details">Details & Info</Link>
            </li>
            <li className=" hover:text-gray-200">
              <Link href="/packages">Packages</Link>
            </li>
            <li className=" hover:text-gray-200">
              <Link href="/schedule">Schedule</Link>
            </li>
            <li className=" hover:text-gray-200">
              <Link href="/transportation">Transportation</Link>
            </li>
            <li className=" hover:text-gray-200">
              <Link href="/gallery">Gallery</Link>
            </li>
          </ul>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        id="header"
        style={{
          transition: 'background-color 0.2s ease',
        }}
        className={`absolute top-0 w-full flex items-center px-3 justify-between py-3 font-medium  bg-[#2B2922] border-y border-[#FFFF4E] md:hidden`}>
        <Link href="/">
          <Image alt="" width={132} height={27} src="/static/images/cabo/cabo-logo-no-tba.png" />
        </Link>

        <div id="flyoutMenu" className={`${visibility}`} style={{ zIndex: 2 }}>
          <div className="overflow-hidden dt flex fr justify-between relative">
            <div className="ma3-ns ma2 fw8 f4 dim white" onMouseDown={handleMouseDown}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" height="20" viewBox="0 0 24 24">
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
              </svg>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <img className="w-3/4" src="/static/images/cabo/beyond-borders-logo-cabo.png" />
          </div>

          <div className="pa5 pt2 flex flex-wrap justify-between w-100">
            <div className="pv3 w-50-ns w-100">
              <h2 className="ttu mt0 mb2 f6-ns f7 fw7 gray" style={{ letterSpacing: '0.08em' }}>
                CABO TAKEOVER
              </h2>

              <ul className={`flex flex-col gap-6 text-white`}>
                <li className=" hover:text-gray-200">
                  <Link href="/">Home</Link>
                </li>
                <li className=" hover:text-gray-200">
                  <Link href="/details">Details & Info</Link>
                </li>
                <li className=" hover:text-gray-200">
                  <Link href="/packages">Packages</Link>
                </li>
                <li className=" hover:text-gray-200">
                  <Link href="/schedule">Schedule</Link>
                </li>
                <li className=" hover:text-gray-200">
                  <Link href="/transportation">Transportation</Link>
                </li>
                <li className=" hover:text-gray-200">
                  <Link href="/gallery">Gallery</Link>
                </li>
              </ul>
            </div>

            <div className="pv3 w-50-ns w-100">
              <h2 className="ttu mt0 mb2 f6 fw7 gray " style={{ letterSpacing: '0.08em' }}>
                Socials
              </h2>
              <a href="https://www.instagram.com/beyondborders.vip" className="white dim no-underline f2-ns f3 b db">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="currentColor"
                  className="inline mr2"
                  viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
                Instagram
              </a>
            </div>
          </div>
        </div>
        <ul className="fr f3-l f4-m f5 dtc pa0 ma0 ">
          <li className="white list" onMouseDown={handleMouseDown}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
            </svg>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default CaboNav;
