import cookie from 'js-cookie';
import moment, { Moment } from 'moment-timezone';
import { useRouter } from 'next/router';
import axios from 'axios';
import { ExportToCsv } from 'export-to-csv';
import { userToken } from './constants';
import { createLogger } from '@/modules/logging/logger';
import { OrderProps, EventProps, EventCartProps, TicketProps, UserTicketProps } from '@/modules/data/types/';
import { forEach } from 'lodash';
import { buildUrl } from 'cloudinary-build-url';
import * as EmailValidator from 'email-validator';
import { TicketMailProps } from '@/modules/data/types/TicketMail';

const logger = createLogger({
  fileLabel: `src/lib/index`,
});

export const instance = axios.create({
  headers: {
    Authorization: 'Bearer ' + process.env.API_KEY,
    'Cache-Control': 'no-cache',
  },
});
export const dev = process.env.NODE_ENV === 'development';
export const serverDomain = dev ? `http://localhost:${process.env.PORT}` : `https://whatstba.com`;
export const stripeClient =
  dev || process.env.STAGING === 'true' ? process.env.STRIPE_DEV_CLIENT : process.env.STRIPE_PROD_CLIENT;

export const axiosConfig = {
  headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' },
};

var yesterday = moment().subtract(1, 'day');

export const timeConstraints = (endDate: Date) => {
  return { hours: { min: moment(endDate).hour(), max: 24, step: 1 } };
};

export const validTicketEndDate = (startDate: Date) => (current: any) => {
  return startDate !== undefined ? current >= moment(startDate).startOf('day') : true;
};
export const getOrderTicketCount = (cart: { [ticketName: string]: EventCartProps }) => {
  const tickets = Object.keys(cart).map((curr) => cart[curr]);
  return tickets.reduce((acc, curr) => acc + curr.quantity, 0);
};

const flatten = (arr) =>
  arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), []);

export const addWorkingDay = (date, days) => {
  let daysToAdd = days;
  const today = moment(date);
  const nextWeekStart = today.clone().add(1, 'week').weekday(1);
  const weekEnd = today.clone().weekday(5);

  const daysTillWeekEnd = Math.max(0, weekEnd.diff(today, 'days'));
  if (daysTillWeekEnd >= daysToAdd) return today.clone().add(daysToAdd, 'days');

  daysToAdd = daysToAdd - daysTillWeekEnd - 1;

  return nextWeekStart.add(Math.floor(daysToAdd / 5), 'week').add(daysToAdd % 5, 'days');
};
export const getSalesByDay = (
  days: number,
  tickets: OrderProps[],
  timeZoneId: string,
): { name: string; amt: number }[] => {
  const dates: {
    [date: string]: { name: string; amt: number };
  } = {};
  tickets.forEach((order) => {
    const date = moment.tz(order.orderDate, timeZoneId).format('MM/DD/YYYY');
    const ticketOrders = order.tickets;
    const allTickets = ticketOrders.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
    const orderSales = allTickets.reduce((acc, curr) => acc + curr.price, 0);
    if (!dates[date]) {
      dates[date] = {
        name: date,
        amt: orderSales,
      };
    } else {
      dates[date] = {
        ...dates[date],
        amt: dates[date].amt + orderSales,
      };
    }
  });
  const getDaysBetweenDates = (startDate, endDate) => {
    var now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format('MM/DD/YYYY'));
      now.add(1, 'days');
    }
    return dates;
  };
  const todaysDate = moment(new Date());
  const startDate = moment(moment(new Date()).subtract(days, 'days'));
  const dateList = getDaysBetweenDates(startDate, todaysDate);
  const combinedDates: {
    [date: string]: { name: string; amt: number };
  } = {};
  dateList.forEach((date) => {
    if (!dates[date]) {
      combinedDates[date] = { amt: 0, name: date };
    } else {
      combinedDates[date] = dates[date];
    }
  });
  return Object.values(combinedDates);
};

export const getTicketsCount = (ticketTypes: { [ticketName: string]: TicketProps }) => {
  const tickets = Object.keys(ticketTypes).map((curr) => ticketTypes[curr]);
  return tickets.reduce((acc, curr) => acc + curr.quantity, 0);
};
export const getTicketsSold = (ticketTypes: { [ticketName: string]: TicketProps }) => {
  const tickets = Object.keys(ticketTypes).map((curr) => ticketTypes[curr]);
  return tickets.reduce((acc, curr) => acc + curr.sold, 0);
};

export const getTotalOrderRev = (tickets: OrderProps[], absorb?: boolean) => {
  const allTickets: UserTicketProps[] = [];
  tickets.forEach((order) => {
    if (order.cleared) allTickets.push(...order.tickets);
  });
  return allTickets.reduce((acc, curr) => acc + curr.price - (absorb ? curr.fee : 0), 0);
};
export const getRemainingBalance = (tickets: OrderProps[], event: EventProps) => {
  const totalRevenue = getTotalOrderRev(tickets);
  const totalPayoutAmt = event.payouts.reduce((acc, curr) => acc + curr.payoutAmount, 0);
  // return totalRevenue - totalPayoutAmt;
  return formatPrice(event.currBalance.toString());
};

export const getTicketsScanned = (ticketTypes: { [ticketName: string]: TicketProps }) => {
  const tickets = Object.keys(ticketTypes).map((curr) => ticketTypes[curr]);
  return tickets.reduce((acc, curr) => acc + curr.sold, 0);
};

export const setCookie = (key: string, value: string) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 1,
      path: '/',
    });
  }
};

export const removeCookie = (key: string) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

export const getCookie = (key: string, req: any) => {
  return process.browser || !req ? getCookieFromBrowser(key) : getCookieFromServer(key, req);
};

export const getCookieFromBrowser = (key: string) => {
  return cookie.get(key);
};

const getCookieFromServer = (key: string, req: any) => {
  if (!req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie.split(';').find((c: any) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const formatDateTimeWithTimeZone = (date: Date, type = 'short', timeZoneId) => {
  switch (type) {
    case 'medium':
      return moment.tz(date, timeZoneId).format('llll');
    case 'short':
      return moment.tz(date, timeZoneId).format('ddd. MMM Do, YYYY [at] h:mm A');
    case 'short-no-year':
      return moment.tz(date, timeZoneId).format('ddd. MMM Do [at] h:mm A');
    case 'date':
      return moment.tz(date, timeZoneId).format('ddd. MMM Do, YYYY');
    case 'shorter':
      return moment.tz(date, timeZoneId).format('MMMM d');
    default:
      return moment.tz(date, timeZoneId).format();
  }
};
export const formatDate = (date: Date, type = 'short') => {
  switch (type) {
    case 'medium':
      return moment(date).format('ddd. MMM Do, YYYY [at] h:mm A');
    case 'short':
      return moment(date).format('ddd, MMM D YYYY');
    case 'shorter':
      return moment(date).format('MMMM Do');
    case 'shortest':
      return moment(date).format('MMM Do');
    default:
      return;
  }
};
export const timeZoneId = 'America/Los_Angeles';

export const formatEventDateTime = (startDate: Date, endDate: Date, timeZone: string) => {
  var nextDay = moment(startDate).tz(timeZone).add(1, 'day');
  return `${moment(startDate).tz(timeZone).format('llll')} - ${
    nextDay.isAfter(endDate)
      ? moment(endDate).tz(timeZone).format('h:mm A z')
      : moment(endDate).tz(timeZone).format('llll z')
  }`;
};
export const formatEventTime = (startDate: Date, endDate: Date, timeZone: string) => {
  var nextDay = moment(startDate).tz(timeZone).add(1, 'day');
  return `${moment(startDate).tz(timeZone).format('h:mm A')} - ${
    nextDay.isAfter(moment(endDate).tz(timeZone))
      ? moment(endDate).tz(timeZone).format('h:mm A')
      : moment(endDate).tz(timeZone).format('llll')
  }`;
};
export const formatPriceInvoice = (date: Date) => {
  return moment(date).format('MM/DD/YYYY');
};

export const formatPriceInvoiceForFile = (date: Date) => {
  return moment(date).format('MM_DD_YYYY');
};

export const formatTime = (date: Date, timeZoneId: string) => moment.tz(date, timeZoneId).format('h:mm A');

export const stripeFee = (total, currency = 'USD'): number => {
  if (currency === 'USD') {
    return total * 0.029 + 0.3;
  }
  return total * 0.044 + 0.3;
};
export const currencySymbol = {
  USD: '$',
  EUR: '€',
  JPY: '¥',
  GBP: '£',
  AUD: 'A$',
  CAD: 'C$',
  CHF: 'Fr.',
  CNY: '¥',
  SEK: 'kr',
  NZD: 'NZ$',
  KRW: '₩',
  SGD: 'S$',
  NOK: 'kr',
  MXN: 'Mex$',
  INR: '₹',
  BRL: 'R$',
  ZAR: 'R',
  RUB: '₽',
  HKD: 'HK$',
  TRY: '₺',
  DKK: 'kr',
  PLN: 'zł',
  TWD: 'NT$',
  AED: 'د.إ',
  SAR: '﷼',
  MYR: 'RM',
  THB: '฿',
  IDR: 'Rp',
  HUF: 'Ft',
  CZK: 'Kč',
  ILS: '₪',
  CLP: '$',
  PHP: '₱',
  COP: 'COL$',
  EGP: 'E£',
  PEN: 'S/',
  VND: '₫',
  IQD: 'ع.د',
  QAR: '﷼',
};

export const supportedCountries = {
  US: { currency: 'USD', country: 'United States' },
  CA: { currency: 'CAD', country: 'Canada' },
  GB: { currency: 'GBP', country: 'United Kingdom' },
  DE: { currency: 'EUR', country: 'Germany' },
  FR: { currency: 'EUR', country: 'France' },
  IT: { currency: 'EUR', country: 'Italy' },
  AU: { currency: 'AUD', country: 'Australia' },
  JP: { currency: 'JPY', country: 'Japan' },
  CN: { currency: 'CNY', country: 'China' },
  IN: { currency: 'INR', country: 'India' },
  KR: { currency: 'KRW', country: 'South Korea' },
  MX: { currency: 'MXN', country: 'Mexico' },
  BR: { currency: 'BRL', country: 'Brazil' },
  RU: { currency: 'RUB', country: 'Russia' },
  SA: { currency: 'SAR', country: 'Saudi Arabia' },
  SE: { currency: 'SEK', country: 'Sweden' },
  TR: { currency: 'TRY', country: 'Turkey' },
  ID: { currency: 'IDR', country: 'Indonesia' },
  PL: { currency: 'PLN', country: 'Poland' },
  ZA: { currency: 'ZAR', country: 'South Africa' },
  CH: { currency: 'CHF', country: 'Switzerland' },
  AR: { currency: 'ARS', country: 'Argentina' },
  HK: { currency: 'HKD', country: 'Hong Kong' },
  TH: { currency: 'THB', country: 'Thailand' },
  TW: { currency: 'TWD', country: 'Taiwan' },
  DK: { currency: 'DKK', country: 'Denmark' },
  NO: { currency: 'NOK', country: 'Norway' },
  MY: { currency: 'MYR', country: 'Malaysia' },
  SG: { currency: 'SGD', country: 'Singapore' },
  AE: { currency: 'AED', country: 'United Arab Emirates' },
  CL: { currency: 'CLP', country: 'Chile' },
  CO: { currency: 'COP', country: 'Colombia' },
  CZ: { currency: 'CZK', country: 'Czech Republic' },
  EG: { currency: 'EGP', country: 'Egypt' },
  HU: { currency: 'HUF', country: 'Hungary' },
  IL: { currency: 'ILS', country: 'Israel' },
  KZ: { currency: 'KZT', country: 'Kazakhstan' },
  MA: { currency: 'MAD', country: 'Morocco' },
  NZ: { currency: 'NZD', country: 'New Zealand' },
  PE: { currency: 'PEN', country: 'Peru' },
  PH: { currency: 'PHP', country: 'Philippines' },
  PK: { currency: 'PKR', country: 'Pakistan' },
  RO: { currency: 'RON', country: 'Romania' },
  UA: { currency: 'UAH', country: 'Ukraine' },
  VN: { currency: 'VND', country: 'Vietnam' },
  BD: { currency: 'BDT', country: 'Bangladesh' },
  BG: { currency: 'BGN', country: 'Bulgaria' },
  BY: { currency: 'BYN', country: 'Belarus' },
  DO: { currency: 'DOP', country: 'Dominican Republic' },
  DZ: { currency: 'DZD', country: 'Algeria' },
  IQ: { currency: 'IQD', country: 'Iraq' },
  KE: { currency: 'KES', country: 'Kenya' },
};

export const timeZoneToCountry = (timezone) => {
  const countries = {
    'America/New_York': 'US',
    'Europe/London': 'GB',
    'Asia/Tokyo': 'JP',
    'Europe/Berlin': 'DE',
    // Add more entries here
  };

  return countries[timezone] ?? 'US';
};
// Format price
export const formatPrice = (number: string, showNumber: boolean = false, currency = 'USD') => {
  const fnumber = parseFloat(number);
  if (fnumber === 0 && !showNumber) return 'FREE';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(fnumber || 0);
};

export const mask = (input: string): string => {
  return input.length > 0 ? input.replace(/\w(?=(?:\W*\w){4})/gm, 'X') : '';
};

export function getCurrentYear() {
  return new Date().getFullYear();
}

export const redirect = (context, target) => {
  logger.info(context, target);
  if (context.res) {
    context.res.writeHead(303, { Location: target });
    context.res.end();
  } else {
    useRouter().replace(target);
  }
};
export const slugify = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaaeeeeiiiioooouuuunc------';

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

export const getLowestPrice = (ticketTypes: { [ticketName: string]: TicketProps }, currency: string = 'USD') => {
  const tickets = Object.keys(ticketTypes).map((curr) => ticketTypes[curr]);
  if (!tickets.length) return 0;
  const availTixs = tickets.filter((tix) => tix.quantity > tix.sold && tix.enabled && !tix.hidden);
  if (!availTixs.length) return 'Sold Out';
  if (availTixs.filter((curr) => curr.ticketType === 'offer').length) {
    return 'Offer Based';
  }
  const lowestPrice = availTixs.reduce((acc, curr) =>
    !curr.enabled || curr.price > acc.price || curr.hidden || curr.sold >= curr.quantity ? acc : curr,
  ).price;

  return formatPrice(lowestPrice?.toString() ?? '', false, currency);
};
const getLowestPriceGoogle = (ticketTypes: { [ticketName: string]: TicketProps }): string => {
  const tickets = Object.keys(ticketTypes).map((curr) => ticketTypes[curr]);
  if (!tickets.length) return '0';
  const lowestPrice = tickets.reduce((acc, curr) => (curr.price > acc.price ? acc : curr)).price;
  return lowestPrice?.toString() ?? '1';
};

export const organizerId = getCookie(userToken, null);
export const handleExport = async (data, title) => {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: `TBA - ${title}`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);

  return csvExporter.generateCsv(data);
};

export const googleStructuralData = (event: EventProps) => `
  <script type="application/ld+json">
      {
      "@context": "https://schema.org",
      "@type": "Event",
      "eventAttendanceMode":"https://schema.org/${
        event.online ? `OnlineEventAttendanceMode` : `OfflineEventAttendanceMode`
      }",
      "name": "${event.name}",
      "startDate": "${event.startDate}",
      "endDate": "${event.endDate}",
      "performer": "${event.name}",
      "eventAttendanceMode": "https://schema.org/${event.online ? `On` : `Off`}lineEventAttendanceMode",
      "eventStatus": "https://schema.org/${event.cancelled ? `EventCancelled` : `EventScheduled`}",
      "location": {
        "@type": "${
          event.online
            ? `VirtualLocation"`
            : `Place",
        "name": "${event.location.venue}"`
        },
        ${
          event.online
            ? `
        "url":"${event.location.url}"`
            : `"address": {
          "@type": "PostalAddress",
          "streetAddress": "${event.location.address}",
          "addressLocality": "${event.location.city}",
          "postalCode": "${event.location.zip}",
          "addressRegion": "${event.location.state}",
          "addressCountry": "US"
        }`
        }
      },
      "image": [
       "${event.image || event.imageWide}"
       ],
      "offers": {
        "@type": "Offer",
				"validFrom": "${event.publishDate}",
        "url": "https://www.whatstba.com/e/${event.slug}",
        "price": "${getLowestPriceGoogle(event.ticketTypes)}",
        "priceCurrency": "USD",
        "availability": "https://schema.org/${
          getLowestPriceGoogle(event.ticketTypes) === '-1' ? `OutOfStock` : `InStock`
        }"
      },
      "organizer": {
        "@type": "Organization",
        "name": "${event.organizerName}",
        "url": "https://whatstba.com/e/${event.slug}"
      },
      ${
        Object.keys(event.lineUp).length
          ? `"performer": [${JSON.stringify(
              Object.keys(event.lineUp).map((key) => {
                return {
                  '@type': 'Person',
                  name: event.lineUp[key].artistName,
                };
              }),
            )}]`
          : ''
      }
    }
    </script>`;

export const eventConfirmationStructuralData = ({
  eventName,
  ticketUrl,
  orderTotal,
  orderId,
  zipCode,
  city,
  state,
  address,
  organizerName,
  startDate,
  endDate,
  eventUrl,
  country,
  venueName,
  firstName,
  lastName,
}: TicketMailProps) => `
  <script type="application/ld+json">
      {
     '@context': 'http://schema.org',
    '@type': 'EventReservation',
    reservationNumber: ${orderId},
    reservationStatus: 'http://schema.org/Confirmed',
    underName: {
      '@type': 'Person',
      name: ${firstName} ${lastName},
    },
    reservationFor: {
      '@type': 'Event',
      name: ${eventName},
      startDate:  ${startDate},
      endDate:  ${endDate},
      location: {
        '@type': ${
          eventUrl
            ? `VirtualLocation`
            : `Place,
        name:  ${venueName}`
        },
        ${
          eventUrl
            ? `url: ${eventUrl}`
            : `
        address: {
          '@type': 'PostalAddress',
          streetAddress:  ${address},
          addressLocality:  ${city},
          addressRegion: ${state},
          postalCode: ${zipCode},
          addressCountry: ${country},
          modifyReservationUrl: ${ticketUrl},
          ticketDownloadUrl: ${ticketUrl},
          url: ${ticketUrl},
          potentialAction: {
            '@type': 'ViewAction',
            url: ${ticketUrl},
            name: 'Open Ticket',
          },
          addressCountry: ${country},
        },`
        }
      },
       "organizer": {
        "@type": "Organization",
        "name": "${organizerName}",
      }
    }
    </script>`;

export const isValidDate = (d: Date) => {
  return d instanceof Date;
};

export const isEventPublic = (event: EventProps) => event.eventType === 'public';

export const isEventLive = (event: EventProps) => !event.cancelled && event.eventStatus === 'live';

export const isSalesEnded = (endDate: Date) => new Date() > new Date(endDate);

export const isEventInPast = (event: EventProps) =>
  new Date(event.endDate) < new Date() && !event.cancelled && event.eventStatus === 'live';

export const isEventNotPublished = (event: EventProps) =>
  new Date(event.endDate) > new Date() && !event.cancelled && event.eventStatus === 'draft';
export const isEventInFuture = (event: EventProps) =>
  new Date(event.endDate) > new Date() && !event.cancelled && event.eventStatus === 'live';

export const hasPublicEventsInFuture = (event: EventProps) => isEventInFuture(event) && isEventPublic(event);

export const areAllTicketsFree = (event: EventProps) => {
  const { ticketTypes } = event;
  let allFree = true;
  Object.keys(ticketTypes).forEach((curr) => {
    if (ticketTypes[curr].price > 0) {
      allFree = false;
    }
  });
  return allFree;
};
export const hasPublicEventsAreLive = (event: EventProps) => isEventLive(event) && isEventPublic(event);

export const hasPublicEventsInPast = (event: EventProps) => isEventInPast(event) && isEventPublic(event);

export const isEventSoldOut = (ticketTypes: EventProps['ticketTypes']) => {
  const tickets = Object.keys(ticketTypes).map((curr) => ticketTypes[curr]);
  const availTixs = tickets.filter((tix) => tix.quantity > tix.sold && tix.enabled && !tix.hidden);
  return !availTixs.length;
};
export const getAverages = (tickets: OrderProps[], events: EventProps[]) => {
  const tixPrices = [];
  const orderPrices = [];
  const tixTotals = [];
  forEach(tickets, (order: OrderProps) => {
    if (!order.guest) {
      orderPrices.push(order.total);
      tixTotals.push(order.tickets.length);
    }
    forEach(order.tickets, (tix: UserTicketProps) => {
      if (!order.guest) {
        tixPrices.push(tix.price);
      }
    });
  });
  const avgTixPrices = tixPrices.reduce((a, b) => a + b, 0) / tixPrices.length;
  const avgOrderPrices = orderPrices.reduce((a, b) => a + b, 0) / orderPrices.length;
  const avgtixCount = tixTotals.reduce((a, b) => a + b, 0) / tixTotals.length;
  return {
    avgTime: getAverageTime(tickets, events),
    avgtixCount: avgtixCount ? avgtixCount.toFixed(1) : 0,
    tixPrice: formatPrice(avgTixPrices.toString(), true),
    orderPrice: formatPrice(avgOrderPrices.toString(), true),
  };
};
const getAverageTime = (tickets, events: EventProps[]) => {
  const times = [];
  forEach(tickets, (order: OrderProps) => {
    const currEvent = events.filter((event) => event._id === order.eventId)?.[0];
    if (!order.guest && currEvent) {
      times.push(moment.tz(order.orderDate, currEvent.location.timeZoneId).format('LTS'));
    }
  });
  var count = times.length;
  if (count === 0) {
    return `N/A`;
  }
  var timesInSeconds = [];
  // loop through times
  for (var i = 0; i < count; i++) {
    // parse
    var pieces = times[i].split(':');
    var ampm = pieces[2].split(' ');
    var hrs = Number(pieces[0]);
    var mins = Number(pieces[1]);
    var secs = Number(ampm[0]);
    var ampm = ampm[1];
    // convert to 24 hr format (military time)
    if (ampm == 'PM') hrs = hrs + 12;
    // find value in seconds of time
    var totalSecs = hrs * 60 * 60;
    totalSecs += mins * 60;
    totalSecs += secs;
    // add to array
    timesInSeconds[i] = totalSecs;
  }
  // find average timesInSeconds
  var total = 0;
  for (var j = 0; j < count; j++) {
    total = total + Number(timesInSeconds[j]);
  }
  var avg = Math.round(total / count);
  // turn seconds back into a time
  var avgMins = Math.floor(avg / 60);
  var avgSecs = avg - 60 * avgMins;
  var avgHrs = Math.floor(avgMins / 60);
  avgMins = avgMins - 60 * avgHrs;
  // convert back to 12 hr. format
  var avgAmpm = 'AM';
  if (avgHrs > 12) {
    avgAmpm = 'PM';
    avgHrs = avgHrs - 12;
  }
  // add leading zeros for seconds, minutes
  const finalAvgSecs = ('0' + avgSecs).slice(-2);
  const finalAvgMins = ('0' + avgMins).slice(-2);
  // your answer
  return `${avgHrs}:${finalAvgMins} ${avgAmpm}`;
};

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
};

export const calculateFees = (price: string, offer = false, currency = 'USD') => {
  const tixPrice = parseInt(price);
  if (tixPrice === 0) return 0;
  if (offer && tixPrice >= 30) return round(0.15 * tixPrice);
  return tixPrice * (currency === 'USD' ? 0.1 : 0.144) + 1.5;
};

export const purple = '#421B51';
export const pink = '#DD40C6';
export const yellow = '#EDDA32';

export const tbaGreen = '#1BBC3E';
export const tbaBlue = '#4E758C';
export const tbaRed = '#D73A25';
export const tbaPink = '#F0B9A9';
export const tbaGray = '#777777';

export const generateUrl = (src: string) =>
  buildUrl(src, {
    cloud: {
      cloudName: 'dzsf703vh',
    },
    // transformations: {
    //   effect: {
    //     name: 'pixelate',
    //     value: 40,
    //   },
    // },
  });

export const calculateTBARev = (orders: OrderProps[], absorbed: string[]): number => {
  let net = 0;
  orders.forEach((curr) => {
    if (curr.eventId && absorbed.includes(curr.eventId)) {
      net += curr.total - getTotalOrderRev([curr], true);
    } else {
      let fees = curr.stripeFee || 0;
      if (!curr.stripeFee && curr.stripeFee !== 0) {
        fees += stripeFee(curr.total) * 100;
      }
      net += curr.total - getTotalOrderRev([curr]) - fees;
    }
  });
  return net;
};

export const calculateStripeFees = (orders: OrderProps[], net: number = 0) =>
  orders.reduce((acc, curr) => {
    let fees = curr.stripeFee || 0;
    net += curr.total - getTotalOrderRev([curr]) - fees;
    if (!curr.stripeFee) {
      fees += stripeFee(curr.total, curr.currency) * 100;
    }
    return acc + fees;
  }, 0);

export function round(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}
export const dateToCron = (date: Moment) => {
  const minutes = date.minutes();
  const hours = date.hours();
  const days = date.date();
  const months = date.month() + 1;
  const year = date.year();

  return `${minutes} ${hours} ${days} ${months} * ${year}`;
};

export const validateEmail = (mail: string) => EmailValidator.validate(mail);

export const isOfferBased = (ticket: TicketProps) => ticket.ticketType === 'offer';

export const isAdmin = (userId) => userId === 'vPpwq47ILlMnJWiYGhHJ3ScH1w73';

export const convertToInstagramPage = (input: string): string => {
  // Remove any leading or trailing spaces from the input
  input = input.trim();

  // Check if the input is an Instagram handle (i.e. starts with '@' or is just the handle)
  const handleRegex = /^[a-zA-Z0-9_.]+$/;
  if (input.startsWith('@')) {
    // Remove the '@' symbol and append it to the Instagram page URL
    return `https://www.instagram.com/${input.slice(1)}/`;
  } else if (handleRegex.test(input)) {
    // Append the handle to the Instagram page URL
    return `https://www.instagram.com/${input}/`;
  }

  // Check if the input is a valid Instagram URL
  const urlRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/([a-zA-Z0-9_.]+)/;
  const match = input.match(urlRegex);
  if (match) {
    // Extract the Instagram handle from the URL and append it to the Instagram page URL
    return `https://www.instagram.com/${match[3]}/`;
  }

  // If the input is not a valid Instagram handle or URL, return an empty string
  return '';
};
export function convertToInstagramHandle(input: string): string {
  // Remove any leading or trailing spaces from the input
  input = input.trim();

  // Check if the input is an Instagram handle (i.e. starts with '@' or is just the handle)
  const handleRegex = /^[a-zA-Z0-9_.]+$/;
  if (input.startsWith('@')) {
    // Remove the '@' symbol and return the Instagram handle
    return input.slice(1);
  } else if (handleRegex.test(input)) {
    // Return the Instagram handle
    return input;
  }

  // Check if the input is a valid Instagram URL
  const urlRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/([a-zA-Z0-9_.]+)/;
  const match = input.match(urlRegex);
  if (match) {
    // Extract the Instagram handle from the URL and return it
    return match[3];
  }

  // If the input is not a valid Instagram handle or URL, return an empty string
  return '';
}

export const expiredOffer = (order: OrderProps) => moment(moment(order.orderDate).add('7', 'days')).isBefore(moment());

export const emailTicketData = (tickets: OrderProps['tickets']) =>
  tickets.reduce((acc, ticket) => {
    const ticketName = ticket.ticketName;
    const existingTicketIndex = acc.findIndex((t) => t.ticketName === ticketName);

    if (existingTicketIndex !== -1) {
      acc[existingTicketIndex].ticketQuantity += 1;
      acc[existingTicketIndex].fee += ticket.fee;
    } else {
      acc.push({
        ticketName,
        ticketQuantity: 1,
        ticketPrice: ticket.price,
        fee: ticket.fee,
      });
    }

    return acc;
  }, []);

export const replaceEmojis = (string: string, replace = '') => {
  const regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  return string.replace(regex, replace);
};

export const extractFirstAndLastName = (fullName) => {
  // Use a regular expression to split the full name into words
  const nameParts = fullName.match(/\S+/g);

  // Check if at least two words are found (first name and last name)
  if (nameParts && nameParts.length >= 2) {
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(' '); // Combine the remaining words as the last name
    return { firstName, lastName };
  } else {
    // Handle cases where there are not enough words (e.g., only one word)
    return { firstName: fullName, lastName: '' };
  }
};
